export const initTwitter = () => {
    const head: any = document.querySelector('head');
    const addTwitterScript = () => {
        const twitterScript = document.createElement('script');
        twitterScript.type = 'text/javascript';
        twitterScript.async = true;
        twitterScript.src = 'https://static.ads-twitter.com/uwt.js';
        head.appendChild(twitterScript);
    };

    // I've converted the base code into the following function which is more
    // readable, this does the same thing as the code you copy from the console

    const initializeTwq = (windowObj: Window & typeof globalThis, documentObj: Document, elementType: string) => {
        if (!windowObj.twq) {
            const twitterEventTracker: any = (windowObj.twq = (...args: any) => {
                twitterEventTracker.exe
                    ? twitterEventTracker.exe.apply(twitterEventTracker, args)
                    : twitterEventTracker.queue.push(args);
            });
            twitterEventTracker.version = '1.1';
            twitterEventTracker.queue = [];
            const twitterScriptElement: any = documentObj.createElement(elementType);
            twitterScriptElement.async = true;
            twitterScriptElement.src = 'https://static.ads-twitter.com/uwt.js';
            const a: any = documentObj.getElementsByTagName(elementType)[0];
            a.parentNode.insertBefore(twitterScriptElement, a);
        }
    };
    addTwitterScript();
    initializeTwq(window, document, 'script');
    window.twq('config', 'oo5yx');
};

export const sendTwitterConversion = (email: any, eventId: any) => {
    if (!window.twq) {
        return;
    }
    window.twq('event', eventId, {
        conversion_id: null,
        email_address: email,
    });
};