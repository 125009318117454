export const initReddit = () => {
    const head = document.querySelector('head'); // Select the <head> element

    const addRedditScript = () => {
        // Create the Reddit script element
        const RedditScript = document.createElement('script');
        RedditScript.src = "https://www.redditstatic.com/ads/pixel.js"; // Reddit Pixel URL
        RedditScript.async = true; // Make it asynchronous
        head?.appendChild(RedditScript); // Append the script to the <head>
    };

    const initializeReddit = (windowObj: Window & typeof globalThis, documentObj: Document, elementType: string) => {
        if (!windowObj.rdt) {
            // Define the Reddit pixel logic
            const p: any = (windowObj.rdt = function () {
                // If `sendEvent` exists, call it; otherwise, queue events
                p.sendEvent
                    ? p.sendEvent.apply(p, arguments)
                    : p.callQueue.push(arguments);
            });
            p.callQueue = []; // Initialize the call queue
            const t: any = documentObj.createElement(elementType); // Create the script element
            t.src = "https://www.redditstatic.com/ads/pixel.js"; // Script source
            t.async = true; // Asynchronous
            const s = documentObj.getElementsByTagName(elementType)[0]; // First script in DOM
            s?.parentNode?.insertBefore(t, s); // Insert the script
        }
    };

    addRedditScript(); // Add the Reddit script to the page
    initializeReddit(window, document, 'script'); // Initialize the Reddit pixel
    window.rdt?.('init', 'a2_g2miqwgbzwb8'); // Initialize Reddit Pixel with the ID
    window.rdt?.('track', 'PageVisit'); // Track the PageVisit event
};

export const sendRedditConversion = (email: string, eventName: string = 'Conversion') => {
    if (!window.rdt) {
        console.warn("Reddit Pixel is not initialized.");
        return;
    }
    // Send a conversion event
    window.rdt('track', 'Conversion', {
        email: email, // User's email
        customEventName: eventName, // Custom event name
    });
};


export const sendCustomConversionEvent = async (
    eventName: string, // Custom event name
    user: any,
    eventMetadata: any
) => {
    const authToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IlNIQTI1NjpzS3dsMnlsV0VtMjVmcXhwTU40cWY4MXE2OWFFdWFyMnpLMUdhVGxjdWNZIiwidHlwIjoiSldUIn0.eyJzdWIiOiJ1c2VyIiwiZXhwIjo0ODg4OTI1OTQwLjE0NDY1NiwiaWF0IjoxNzMzMTY1OTQwLjE0NDY1NiwianRpIjoiRjJlc1p1b3ItRlBIWVZucmc5bVFuakREYlpicXRRIiwiY2lkIjoiMVExRU96VFBXbll2ZXJocHR2Z1dzUSIsImxpZCI6InQyXzFlN2JnZG41MmIiLCJhaWQiOiJ0Ml8xZTdiZ2RuNTJiIiwibGNhIjoxNzMzMTY1OTM4NTcxLCJzY3AiOiJlSnlLVmtwTUtVN096eXRMTFNyT3pNOHJWb29GQkFBQV9fOUJGZ2J1IiwiZmxvIjoxMCwibGwiOnRydWV9.CDybfp04fDf8d33KJj1ec1PodMDx-t127CAedeTc2WOyaxxl9V7q9cN6e8D_ZaK_6OQ_N89B5_WvWLmdD1EVMzr-iaBWjPlLds-xPOEGzilnN99ZNsuAETA3CnE7vXR9K7VTXcT_IpFt9uvg494oYayKpAgBoq_TcLc2o5joPihpXLyzvlnDi6ISRZN9IT3PG9Yd7Gk-RVVgSaqwUumlQSt8nLe-v7Bxsrwoo7fJ4BYaS9zHQ4eUM7Y9Q0GicgDb7RWdJoXFy-YQK5Go4_aSMfaO1KFEzx9u26SefdMsMXRrPeWURFmRuBGT5fmPmwD4FIbp493W_2xzNNG-AS5yYA'
    const pixelId = "a2_g2miqwgbzwb8"; // Replace with your Reddit Pixel ID
    const apiUrl = `https://ads-api.reddit.com/api/v2.0/conversions/events/${pixelId}`;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            mode: "no-cors",
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`,
            }), 
            body: JSON.stringify({
                test_mode: true,
                events: [
                    {
                        event_at: new Date().toISOString(),
                        event_type: {
                            tracking_type: "Custom",
                            custom_event_name: eventName, // Name of the custom event
                        },
                        // click_id: clickId, // Attribution click ID
                        user: user,
                        event_metadata: eventMetadata,
                    },
                ],
            }),
        });

        // console.error("Error sending custom conversion event:", response);
        // if (!response.ok) {
        //     // throw new Error(
        //     //     `Failed to send custom conversion event: ${response.status} ${response.statusText}`
        //     // );
        // }

        // const result = await response.json();
        // console.log("Custom conversion event sent successfully:", result);
        // return result;
    } catch (error) {
        // console.error("Error sending custom conversion event:", error);
        // throw error;
    }
};
