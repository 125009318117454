
import SideBar from 'src/components/Header/SideBar';
import React, { useContext } from 'react';
import TagManager from 'react-gtm-module'
import { Outlet, useNavigate } from 'react-router-dom';
import { ThemeDetailsContext } from './context/ThemeContext';
import FooterDashboard from 'src/components/Header/Footer/FooterDashboard';
import { useAuth } from "src/context/AuthContext";
import Header from './components/Header';
import hasAccess from "src/utils/guard";
import { SUBSCRIPTION_PLANS } from './constant';
import { trackEvent } from './utils/facebookConversionApi';
import { sendTwitterConversion } from './utils/twttierConversionApi';
import { sendCustomConversionEvent, sendRedditConversion } from './utils/redditConversionApi';

export const Layout = () => {
    const { theme, setTheme } = useContext(ThemeDetailsContext)
    const navigate = useNavigate();

    const { user } = useAuth();

    const hasAcess = hasAccess([SUBSCRIPTION_PLANS.PREMIUM_PLUS, SUBSCRIPTION_PLANS.PROFESSIONAL], user)
    if (!hasAcess) {
        navigate('/pricing')
    } else {
        const currentTimestamp = Date.now();
        const timeDifference = currentTimestamp - user.current_period_start;
        if (timeDifference < 10 * 60 * 1000) {
            trackEvent('SubscribeEvent', {
                emails: [user.email || ''],
                firstName: user.firstName || '',
                lastName: user?.lastName || '',
                name: `${user.firstName} ${user.lastName}`,
            });

            TagManager.dataLayer({
                dataLayer: {
                    event: "conversion",
                    send_to: 'AW-16475417405/KS0NCI-U17AZEL3Wi7A9',
                    'value': 1.0,
                    'currency': 'USD',
                },
            });

            window.dataLayer.push();
            sendTwitterConversion(user?.email, 'tw-oo5yx-oo5zi');
            sendRedditConversion(user?.email, 'SubscribeEvent');

            const userforRedit: any = {
                // ipAddress: "192.168.1.1",
                // userAgent: "Mozilla/5.0",
                email: user?.email,
                // externalId: "external-id-123",
                // idfa: "idfa-12345",
                // aaid: "aaid-67890",
                uuid: user?.userId
            };

            const eventMetadata: any = {
                conversionId: user?.subscriptionId,
                stripeCustomerId: user?.stripeCustomerId,
                subscriptionName: user?.subscriptionName,
            };



            sendCustomConversionEvent('SubscribeEvent', userforRedit, eventMetadata)
            // window.twq('event', ' tw-oo5yx-oo5zi', {
            //     email_address: user.email
            // });
        }
    }


    return (
        <>
            {
                hasAcess && <Header.Private />
            }

            <main className='app-main d-flex'>
                <SideBar theme={theme} setTheme={setTheme} />
                <div className='app-content d-flex flex-grow-1 flex-column position-relative'>
                    <div id='container-fluid' className='container-fluid p-0 p-lg-3'>

                        <div className='app-card'>
                            {
                                hasAcess && <Outlet />
                            }

                        </div>
                    </div>
                    <FooterDashboard />
                </div>
            </main>
        </>
    );
}
